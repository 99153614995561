export var OverlayStatus;
(function (OverlayStatus) {
    OverlayStatus["Open"] = "open";
    OverlayStatus["Closed"] = "closed";
})(OverlayStatus || (OverlayStatus = {}));
export var OverlayInteraction;
(function (OverlayInteraction) {
    OverlayInteraction["Open"] = "open";
    OverlayInteraction["Close"] = "close";
    OverlayInteraction["ChangeMainImage"] = "change main image";
})(OverlayInteraction || (OverlayInteraction = {}));
export var DrawerInteraction;
(function (DrawerInteraction) {
    DrawerInteraction["Open"] = "open drawer";
    DrawerInteraction["Close"] = "close drawer";
})(DrawerInteraction || (DrawerInteraction = {}));
export var Element;
(function (Element) {
    Element["Icon"] = "icon";
    Element["Checkbox"] = "checkbox";
    Element["Button"] = "button";
    Element["Link"] = "link";
    Element["Switch"] = "switch";
    Element["InputWrapper"] = "input-wrapper";
    Element["DrawerBackdrop"] = "drawer-backdrop";
    Element["Image"] = "image";
    Element["Dropdown"] = "dropdown";
    Element["Input"] = "input";
})(Element || (Element = {}));
export var NavigationParent;
(function (NavigationParent) {
    NavigationParent[NavigationParent["GlobalHeader"] = 0] = "GlobalHeader";
    NavigationParent[NavigationParent["ShopInShopNav"] = 1] = "ShopInShopNav";
})(NavigationParent || (NavigationParent = {}));
export var TrackingEventName;
(function (TrackingEventName) {
    TrackingEventName["CommonDisclaimerClick"] = "common-disclaimer_click";
})(TrackingEventName || (TrackingEventName = {}));
