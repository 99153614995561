var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { background, border, borderColor, borderStyle, borderWidth, display, space, } from 'styled-system';
import { focusOutlineCSSWithOffset } from '../../theme/styles';
import { boxSizing, cursor } from '../../theme/system-utilities';
export var SPINNER_SIZE = 24;
export var ButtonBase = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", ";\n"])), space, display, background, border, borderStyle, borderColor, borderWidth, cursor, boxSizing, focusOutlineCSSWithOffset);
ButtonBase.defaultProps = {
    display: 'inline-flex',
    m: 'sp_0',
    p: 'sp_0',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    boxSizing: 'border-box',
};
var templateObject_1;
