var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { backgroundColor, borderColor, borderRadius, color, fontWeight, justifyContent, size, textAlign, themeGet, } from 'styled-system';
import { font } from '../../theme/helper';
import { ButtonBase, SPINNER_SIZE } from './Base';
var BG_HOVER = 'colors.sys.neutral.background.hover';
var COLOR_ACTIVE = 'colors.sys.neutral.text.strong';
var BG_ACTIVE = 'colors.sys.neutral.background.default';
var COLOR_DISABLED = 'colors.sys.neutral.background.inverted';
var BORDER_DISABLED = 'colors.sys.neutral.background.inverted';
export var ButtonSecondary = styled(ButtonBase)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  [role='progressbar'] svg {\n    ", ";\n    stroke: ", ";\n  }\n\n  cursor: ", ";\n\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  [role='progressbar'] svg {\n    ", ";\n    stroke: ", ";\n  }\n\n  cursor: ", ";\n\n  ", ";\n"])), color, fontWeight, textAlign, justifyContent, backgroundColor, borderRadius, function (props) { return font(props.fontSize || 3, props.lineHeight)(props); }, function (_a) {
    var spinnerSize = _a.spinnerSize;
    return size(spinnerSize || SPINNER_SIZE);
}, themeGet('colors.sys.neutral.background.inverted'), function (_a) {
    var isLoading = _a.isLoading, disabled = _a.disabled;
    return isLoading ? 'progress' : disabled ? 'not-allowed' : 'pointer';
}, function (props) {
    return !props.disabled
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          &:hover {\n            border-style: solid;\n            border-width: ", ";\n            border-color: ", ";\n            background-color: ", ";\n          }\n          &:active {\n            color: ", ";\n            background-color: ", ";\n            border-style: solid;\n            border-width: ", ";\n            border-color: ", ";\n          }\n        "], ["\n          &:hover {\n            border-style: solid;\n            border-width: ", ";\n            border-color: ", ";\n            background-color: ", ";\n          }\n          &:active {\n            color: ", ";\n            background-color: ", ";\n            border-style: solid;\n            border-width: ", ";\n            border-color: ", ";\n          }\n        "])), themeGet('borderWidths.small')(props), themeGet('colors.sys.neutral.border.default')(props), themeGet(BG_HOVER)(props), themeGet(COLOR_ACTIVE)(props), themeGet(BG_ACTIVE)(props), themeGet('borderWidths.small')(props), themeGet('colors.sys.neutral.border.strong')(props)) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          color: ", ";\n          border-color: ", ";\n        "], ["\n          color: ", ";\n          border-color: ", ";\n        "])), themeGet(COLOR_DISABLED)(props), themeGet(BORDER_DISABLED)(props));
});
ButtonSecondary.defaultProps = __assign(__assign({}, ButtonBase.defaultProps), { px: 'sp_16', py: 'sp_8', color: 'sys.neutral.border.strong', fontWeight: 'bold', textAlign: 'center', justifyContent: 'center', backgroundColor: 'sys.neutral.background.default', borderRadius: 'small', borderStyle: 'solid', borderWidth: 'small', borderColor: 'sys.neutral.border.hover' });
export var ButtonSecondary_V2 = styled(ButtonSecondary)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n\n  ", ";\n"], ["\n  ", "\n\n  ", ";\n"])), borderColor, function (props) {
    return !props.disabled
        ? css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n          &:hover,\n          &:focus {\n            color: ", ";\n            border-color: ", ";\n            background-color: ", ";\n          }\n\n          &:active {\n            color: ", ";\n            border-color: ", ";\n            background-color: ", ";\n          }\n        "], ["\n          &:hover,\n          &:focus {\n            color: ", ";\n            border-color: ", ";\n            background-color: ", ";\n          }\n\n          &:active {\n            color: ", ";\n            border-color: ", ";\n            background-color: ", ";\n          }\n        "])), themeGet('colors.sys.neutral.text.hover')(props), themeGet('colors.sys.neutral.border.hover')(props), themeGet('colors.sys.neutral.background.hover')(props), themeGet('colors.sys.neutral.text.active')(props), themeGet('colors.sys.neutral.border.active')(props), themeGet('colors.sys.neutral.background.active')(props)) : css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n          color: ", ";\n          border-color: ", ";\n          background-color: ", ";\n        "], ["\n          color: ", ";\n          border-color: ", ";\n          background-color: ", ";\n        "])), themeGet('colors.sys.neutral.text.inactive')(props), themeGet('colors.sys.neutral.border.inactive')(props), themeGet('colors.sys.neutral.background.inactive')(props));
});
ButtonSecondary_V2.defaultProps = __assign(__assign({}, ButtonSecondary.defaultProps), { color: 'sys.neutral.text.default', borderColor: 'sys.neutral.border.default', backgroundColor: 'sys.neutral.background.default' });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
