var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { themeGet } from 'styled-system';
import theme from './theme';
export var getThemeValue = function (key) { return themeGet(key)({ theme: theme }); };
export var getThemeColor = function (color) { return themeGet("colors.".concat(color)); };
export var breakpoint = function (value) { return function (props) { return "\n  @media (min-width: ".concat(typeof value !== 'string' ? props.theme.breakpoints[value] : value, ")\n"); }; };
export var breakpointMinMax = function (min, max) { return function (props) {
    var minValue = typeof min !== 'string' ? props.theme.breakpoints[min] : min;
    var _a = __read(props.theme.breakpoints[max].match(/\d+/g), 1), maxValue = _a[0];
    return "\n    @media (min-width: ".concat(minValue, ") and (max-width: ").concat(maxValue - 1, "px)\n  ");
}; };
export var fontSize = function (value) { return function (props) { return "font-size: ".concat(props.theme.fontSizes[value], "px"); }; };
var MAGIC_LINE_HEIGHT_COEFFICIENT = 1.25;
export var font = function (size, height) {
    if (size === void 0) { size = 0; }
    if (height === void 0) { height = -1; }
    return function (props) {
        height =
            height === -1
                ? props.theme.space.findIndex(function (spacing) { return spacing >= props.theme.fontSizes[size] * MAGIC_LINE_HEIGHT_COEFFICIENT; })
                : height;
        return "\n    ".concat(fontSize(size)(props), ";\n    ").concat(lineHeight(height)(props), "\n  ");
    };
};
var boxModel = function (prefix) {
    return function (top, right, bottom, left
    // eslint-disable-next-line max-params
    ) {
        if (right === void 0) { right = top; }
        if (bottom === void 0) { bottom = top; }
        if (left === void 0) { left = right; }
        return function (props) {
            return Object.entries({ top: top, right: right, bottom: bottom, left: left })
                .map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return value !== null && "".concat(prefix && "".concat(prefix, "-")).concat(key, ": ").concat(space(value)(props));
            })
                .filter(Boolean)
                .join(';');
        };
    };
};
export var absolute = boxModel('');
export var padding = boxModel('padding');
export var margin = boxModel('margin');
/**
 * @deprecated
 * Use themeBoxShadow from system-utilities instead
 */
export var boxShadow = function (value) { return function (props) { return "box-shadow: ".concat(props.theme.boxShadows[value]); }; };
export var space = function (value) { return function (props) {
    return typeof props.theme.space[value] !== 'undefined' ? "".concat(props.theme.space[value], "px") : value;
}; };
export var lineHeight = function (value) { return function (props) { return "line-height: ".concat(space(value)(props)); }; };
export var modifiers = function (props) {
    if (!props.modifiers) {
        return '';
    }
    var styles = [];
    props.modifiers.forEach(function (modifier) {
        var _a = __read(modifier.split('.')), key = _a[0], rest = _a.slice(1);
        var value = rest.join('.');
        switch (key) {
            case 'font-style':
            case 'font-weight':
                styles.push("".concat(key, ": ").concat(value, " !important"));
                break;
            case 'color':
                var color = value[0] === '#' ? value : getThemeColor(value)(props);
                styles.push("".concat(key, ": ").concat(color, " !important"));
                break;
            default:
            // Nothing should happen
        }
    });
    return styles.join(';');
};
export var textWithEllipsis = function (linesCount) {
    if (linesCount === void 0) { linesCount = 2; }
    return "\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-line-clamp: ".concat(linesCount, ";\n  -webkit-box-orient: vertical;\n");
};
