var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { borderRadius, color, fontWeight, justifyContent, size, textAlign, themeGet, } from 'styled-system';
import { font } from '../../theme/helper';
import { ButtonBase, SPINNER_SIZE } from './Base';
export var ButtonGreen = styled(ButtonBase)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  [role='progressbar'] svg {\n    ", ";\n    stroke: ", ";\n  }\n\n  cursor: ", ";\n\n  ", "\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  [role='progressbar'] svg {\n    ", ";\n    stroke: ", ";\n  }\n\n  cursor: ", ";\n\n  ", "\n"])), color, fontWeight, textAlign, justifyContent, borderRadius, function (props) { return font(props.fontSize || 3, props.lineHeight)(props); }, function (_a) {
    var spinnerSize = _a.spinnerSize;
    return size(spinnerSize || SPINNER_SIZE);
}, themeGet('colors.sys.neutral.background.default'), function (_a) {
    var isLoading = _a.isLoading, disabled = _a.disabled;
    return isLoading ? 'progress' : disabled ? 'not-allowed' : 'pointer';
}, function (props) {
    return !props.disabled
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          &:active {\n            background-color: ", ";\n          }\n        "], ["\n          &:active {\n            background-color: ", ";\n          }\n        "])), themeGet('colors.sys.success.background.hover')(props)) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          color: ", ";\n          [role='progressbar'] svg {\n            stroke: ", ";\n          }\n        "], ["\n          color: ", ";\n          [role='progressbar'] svg {\n            stroke: ", ";\n          }\n        "])), themeGet('colors.sys.neutral.background.default')(props), themeGet('colors.sys.neutral.background.default')(props));
});
ButtonGreen.defaultProps = __assign(__assign({}, ButtonBase.defaultProps), { backgroundColor: 'sys.success.background.default', px: 'sp_16', py: 'sp_8', color: 'sys.neutral.background.default', fontWeight: 'bold', textAlign: 'center', justifyContent: 'center', borderRadius: 'small' });
var templateObject_1, templateObject_2, templateObject_3;
